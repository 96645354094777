 /* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        /*============================================================================
          PRICE SLIDER
        ==============================================================================*/

        function priceSlider() {

          var userDeviceWidth = $(window).width();
          if ( userDeviceWidth >= 1024 ) {
            var slider = document.getElementById('price-slider'),
                sliderMin = document.getElementById('slider-min'),
                sliderMax = document.getElementById('slider-max');
          } else {
            var slider = document.getElementById('price-slider-mobile'),
                sliderMin = document.getElementById('slider-min-mobile'),
                sliderMax = document.getElementById('slider-max-mobile');
          }

          if(slider) {

            if( $('#outer-wrapper').attr('data-min') ) {
              var userMin = parseInt( $('#outer-wrapper').attr('data-min') );
              // console.log(userMin);
            } else {
              userMin = 500;
            }

            if( $('#outer-wrapper').attr('data-max') ) {
              userMax = parseInt( $('#outer-wrapper').attr('data-max') );
              // console.log(userMax);
            } else {
              userMax = 5000;
            }

            noUiSlider.create(slider, {
                start: [userMin, userMax],
                connect: true,
                step: 500,
                ariaFormat: wNumb({
                    decimals: 0
                }),
                format: wNumb({
                    decimals: 0,
                    thousand: '',
                    prefix: '$'
                }),
                range: {
                    'min': 500,
                    'max': 5000
                }
            });

            var snapValues = [
              sliderMin,
              sliderMax
            ];

            slider.noUiSlider.on('update', function (values, handle) {
                snapValues[handle].innerHTML = values[handle];
            });
          }
        }
        priceSlider();

        /*============================================================================
          CUSTOM SELECT DROPDOWNS
        ==============================================================================*/

        function customSelect() {
          var x, i, j, selElmnt, a, b, c;
          /* Look for any elements with the class "custom-select": */
          x = document.getElementsByClassName("custom-select");
          for (i = 0; i < x.length; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0];
            /* For each element, create a new DIV that will act as the selected item: */
            a = document.createElement("DIV");
            a.setAttribute("class", "select-selected");
            a.setAttribute("id", "select-selected" + i);
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            /* For each element, create a new DIV that will contain the option list: */
            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");
            for (j = 1; j < selElmnt.length; j++) {
              /* For each option in the original select element,
              create a new DIV that will act as an option item: */
              c = document.createElement("DIV");
              c.innerHTML = selElmnt.options[j].innerHTML;
              var termValue = selElmnt.options[j].value;
              if(termValue !== 'All') {
                c.setAttribute('data-id', termValue);
              }
              if(termValue === 'All') {
                c.setAttribute("class", "all-option");
              }

              c.addEventListener("click", function(e) {
                  /* When an item is clicked, update the original select box,
                  and the selected item: */
                  var y, i, k, s, h;
                  s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                  h = this.parentNode.previousSibling;
                  for (i = 0; i < s.length; i++) {
                    if (s.options[i].innerHTML === this.innerHTML) {
                      s.selectedIndex = i;
                      h.innerHTML = this.innerHTML;
                      // t = this.parentNode.getElementsByClassName("all-option");
                      // for (k = 0; k < t.length; k++) {
                      //   t[k].removeAttribute("class");
                      // }
                      y = this.parentNode.getElementsByClassName("same-as-selected");
                      w = this.parentNode.getElementsByClassName("home-selected");
                      t = this.parentNode.getElementsByClassName("all-option");
                      for (k = 0; k < y.length; k++) {
                        y[k].removeAttribute("class");
                      }
                      for (u = 0; u < t.length; u++) {
                        t[u].removeAttribute("class");
                      }
                      for (z = 0; z < w.length; z++) {
                        w[z].removeAttribute("class");
                      }
                      this.setAttribute("class", "same-as-selected");
                      break;
                    }
                  }
                  h.click();
              });
              b.appendChild(c);
            }
            x[i].appendChild(b);
            a.addEventListener("click", function(e) {
              /* When the select box is clicked, close any other select boxes,
              and open/close the current select box: */
              e.stopPropagation();
              closeAllSelect(this);
              this.nextSibling.classList.toggle("select-hide");
              this.classList.toggle("select-arrow-active");
            });
          }

          function closeAllSelect(elmnt) {
            /* A function that will close all select boxes in the document,
            except the current select box: */
            var x, y, i, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            for (i = 0; i < y.length; i++) {
              if (elmnt === y[i]) {
                arrNo.push(i);
              } else {
                y[i].classList.remove("select-arrow-active");
              }
            }
            for (i = 0; i < x.length; i++) {
              if (arrNo.indexOf(i)) {
                x[i].classList.add("select-hide");
              }
            }
          }

          /* If the user clicks anywhere outside the select box,
          then close all select boxes: */
          document.addEventListener("click", closeAllSelect);

          $('.city .select-items div').each(function() {
            if ( $(this).attr('data-id') === $('#outer-wrapper').attr('data-city') ) {
              $(this).addClass('home-selected');
              var userCity = $(this).html();
              // console.log(userCity);
              $('.city .select-selected').html(userCity);
              if ( userCity !== 'All') {
                t = this.parentNode.getElementsByClassName("all-option");
                for (u = 0; u < t.length; u++) {
                  t[u].removeAttribute("class");
                }
              }
            }
          });

          $('.unit-type .select-items div').each(function() {
            if ( $(this).attr('data-id') === $('#outer-wrapper').attr('data-unit') ) {
              $(this).addClass('home-selected');
              var userUnit = $(this).html();
              // console.log(userUnit);
              $('.unit-type .select-selected').html(userUnit);

              if ( userUnit !== 'All') {
                t = this.parentNode.getElementsByClassName("all-option");
                for (u = 0; u < t.length; u++) {
                  t[u].removeAttribute("class");
                }
              }

            }
          });

        }

        customSelect();


        /*============================================================================
          SEARCH APARTMENTS - HOME PAGE
        ==============================================================================*/

        function searchApts() {

          if ($('body').hasClass('has-search-overlay') ) {
            $('body').removeClass('has-search-overlay');
          }

          var userDeviceWidth = $(window).width();
          if ( userDeviceWidth >= 1024 ) {
            var userCity = $('.city-select').find('.same-as-selected').attr('data-id');
            var userUnit = $('.unit-select').find('.same-as-selected').attr('data-id');
            var userMin = $('#slider-min').html().substring(1);
            var userMax = $('#slider-max').html().substring(1);
          } else {
            var userCity = $('.city-select').find('.same-as-selected').attr('data-id');
            var userUnit = $('.unit-select').find('.same-as-selected').attr('data-id');
            var userMin = $('#slider-min-mobile').html().substring(1);
            var userMax = $('#slider-max-mobile').html().substring(1);
          }

          $('#outer-wrapper').attr({'data-city':userCity, 'data-unit':userUnit, 'data-min':userMin, 'data-max':userMax});

          $.ajaxSetup({
            cache: false,
            type: 'POST',
            dataType: 'text'
          });

          $('#outer-wrapper').load('/find-an-apartment/' + ' #ajax-wrapper',{noncache: new Date().getTime()},function(responseText, textStatus, req){

            if('/find-an-apartment/'!==location.pathname){
              window.history.pushState({path: '/find-an-apartment/'},'','/find-an-apartment/');
              $('title').text('Find An Apartment | Peterson Rentals');
            }

            $('html, body').animate({
              scrollTop: $('.search-container').offset().top - 100
            }, 700);

            var userCity = $('#outer-wrapper').attr('data-city');
            var userUnit = $('#outer-wrapper').attr('data-unit');
            var userMin = $('#outer-wrapper').attr('data-min');
            var userMax = $('#outer-wrapper').attr('data-max');

            // console.log(userCity);
            // console.log(userUnit);
            // console.log(userMin);
            // console.log(userMax);

            $('.unit-results').html('<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>');

            if ( userCity ) {
              var cityPrmtr = 'city=' + userCity + '&';
            } else {
              var cityPrmtr = '';
            }
            if ( userUnit ) {
              var unitPrmtr = 'unittype=' + userUnit + '&';
            } else {
              var unitPrmtr = '';
            }

            var restURL = '/wp-json/wp/v2/units?' + cityPrmtr + unitPrmtr + 'per_page=100&_embed';
            var propURL = '/wp-json/wp/v2/properties?order=asc&orderby=title';

            // console.log(restURL);
            // console.log(propURL);

            $.getJSON(propURL, function(prop) {

                // var practiceArea;

                $.getJSON(restURL, function(data) {

                  var newData = [];
                  for (var k = 0; k < data.length; k++ ) {
                    // console.log(data[k].price_range);
                    var sMin = parseInt(userMin);
                    var sMax = parseInt(userMax);
                    var price = parseInt(data[k].price_range);

                    if ( price >= userMin && price <= userMax ) {
                      newData.push(data[k]);
                    }
                  }

                  // console.log(newData);

                  if(newData.length !== 0) {
                    $i = 1;
                    $j = 1;

                    // console.log(data);
                    var output = '';

                    $.each(prop, function(k, v) {

                      var unitData = [];

                      for (var m = 0; m < newData.length; m++ ) {
                        // console.log(newData[m].building.toString());
                        var propBuilding = v.building.toString();
                        var unitBuilding = newData[m].building.toString();
                        // console.log(unitBuilding);
                        // console.log(propBuilding);
                        if ( unitBuilding === propBuilding ) {
                          // console.log('works');
                          unitData.push(newData[m]);
                        }
                      }
                      console.log(unitData);

                      if (unitData.length > 0 ) {
                        output += '<div class="property-search-result flex-grid flex-grid--fluid-gutter">';
                        output += '<div class="col col35 property"><div class="property-sticky"><div class="property-image u-ofit"><a href="' + v.link + '"><img src="' + v.image + '" alt="' + v.alt + '" /></a></div><div class="property-details"><a class="blk" href="' + v.link + '"><h3>' + v.name + '</h3><p>' + v.address + '</p></a><a class="has-arrow blue" href="' + v.link + '">View Building</a></div></div></div>';
                        output += '<div class="col col60 property-units"><div class="unit-toggle">';
                        output += unitData.length;
                        if ( unitData.length === 1 ) {
                          output += ' Unit Available';
                        } else {
                          output += ' Units Available';
                        }
                        output += '</div><div class="units-content">';
                      }

                      $.each(unitData, function(key, val) {

                        output += '<div class="unit col">';
                        output += '<h2>' + val.unit_number + '</h2>';
                        output += '<div class="flex-grid flex-grid--fluid-gutter"><div class="col30"><h4>Features</h4><ul class="u-no-bullets">';
                        for (var i in val.features) {
                          output += '<li>' + val.features[i] + '</li>';
                        }
                        output += '</ul>';
                        if (val.floorplan) {
                          output += '<a class="download-link u-show-for-mobile" href="' + val.floorplan + '" target="_blank">Floorplan</a>';
                        }
                        output += '</div>';
                        output += '<div class="col30"><h4>Size</h4><ul class="u-no-bullets">';
                        output += '<li>' + val._embedded['wp:term'][1][0].name + ' </li>';
                        output += '<li>' + val.size_sqft + ' SQFT</li>';
                        output += '</ul></div>';
                        output += '<div class="col30"><h4>Status</h4><ul class="u-no-bullets">';
                        if(val.available.length >= 1) {
                          output += '<li>Available Immediately</li>';
                        } else {
                          output += '<li>Available ' + val.avail_date + '</li>';
                        }
                        output += '<li>$' + val.price_range + ' per month</li>';
                        if(val.custom_status !== null ) {
                          output += '<li>' + val.custom_status + '</li>';
                        }
                        output += '</ul></div><div class="col30 u-hide-for-mobile">';
                        if (val.floorplan) {
                        output += '<a class="download-link" href="' + val.floorplan + '" target="_blank">Floorplan</a>';
                        }
                        output += '</div><div class="col65"><button class="request-viewing" data-content=".content-' + val.unique_id + '">Request Viewing</button></div></div>';
                        output += '</div>';
                        output += '<div class="modal-content modal-content__viewing content-' + val.unique_id + '" data-unit="' + val.unit_number + '" data-building="' + val._embedded['wp:term'][0][0].name + '">';
                        output += '<div class="flex-grid flex-grid--fluid-gutter"><div class="col col40"><h2 class="allcaps-heading">Request a Viewing</h2><h3>' + val.unit_number + ' &ndash; ' + val._embedded['wp:term'][0][0].name + '</h3>';
                        output += '<ul><li>' + val._embedded['wp:term'][1][0].name + ' </li>';
                        output += '<li>Available ' + val.avail_date + ' </li>';
                        output += '<li>$' + val.price_range + ' per month</li></ul>';
                        output += '</div><div class="col col55"><div class="contact-form">' + val.form_shortcode + '</div></div></div></div>';
                    });
                    output += '</div></div>';
                    output += '</div>';
                    });

                    output += '';
                    $('.unit-results').html(output);

                  } else {
                    // console.log(data);
                    var output = '<div class="property-search-result no-result flex-grid flex-grid--fluid-gutter"><h3>Sorry, no results were found. Please search again.</h3></div>';

                    $('.unit-results').html(output);
                  }

                }); //get JSON
             // console.log(prop);
            });//get JSON properties
            // mobileSearch();

            // $('#select-selected2').html('Hello');
            // $('#select-selected3').html('GoodBye');

            customSelect();
            //update select fields to match search
            priceSlider();

            $('.trigger-search-form').click(function() {
              // console.log('test');
              searchForm();
            });

            toggleNav();

            // searchForm();

          });

        }

        $('.trigger-search').click(function() {
          searchApts();
        });

        /*============================================================================
          SEARCH APARTMENTS - FIND AN APARTMENT PAGE
        ==============================================================================*/

        function searchForm() {
          // console.log('search-form');

            if ($('body').hasClass('has-search-overlay') ) {
              $('body').removeClass('has-search-overlay');
            }

            $('html, body').animate({
              scrollTop: $('.search-container').offset().top - 100
            }, 700);

            var userDeviceWidth = $(window).width();
            if ( userDeviceWidth >= 1024 ) {
              var selectCity = $('.city').find('.same-as-selected').attr('data-id');
              var selectUnit = $('.unit-type').find('.same-as-selected').attr('data-id');
              var sliderMin = $('#slider-min').html().substring(1);
              var sliderMax = $('#slider-max').html().substring(1);
            } else {
              var selectCity = $('.city').find('.same-as-selected').attr('data-id');
              var selectUnit = $('.unit-type').find('.same-as-selected').attr('data-id');
              var sliderMin = $('#slider-min-mobile').html().substring(1);
              var sliderMax = $('#slider-max-mobile').html().substring(1);
            }

            // console.log(selectCity);
            // console.log(selectUnit);
            // console.log(sliderMin);
            // console.log(sliderMax);

            $('.unit-results').html('<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>');

            if ( selectCity ) {
              var cityPrmtr = 'city=' + selectCity + '&';
            } else {
              var cityPrmtr = '';
            }
            if ( selectUnit ) {
              var unitPrmtr = 'unittype=' + selectUnit + '&';
            } else {
              var unitPrmtr = '';
            }

            var restURL = '/wp-json/wp/v2/units?' + cityPrmtr + unitPrmtr + 'per_page=100&_embed';
            var propURL = '/wp-json/wp/v2/properties?order=asc&orderby=title';


            // console.log(restURL);
            // console.log(propURL);

            $.getJSON(propURL, function(prop) {

                // var practiceArea;

                $.getJSON(restURL, function(data) {

                  var newData = [];
                  for (var k = 0; k < data.length; k++ ) {
                    // console.log(data[k].price_range);
                    var sMin = parseInt(sliderMin);
                    var sMax = parseInt(sliderMax);
                    var price = parseInt(data[k].price_range);

                    if ( price >= sliderMin && price <= sliderMax ) {
                      newData.push(data[k]);
                    }
                  }

                  if(newData.length !== 0) {
                    $i = 1;
                    $j = 1;

                    // console.log(data);
                    var output = '';

                    $.each(prop, function(k, v) {

                      var unitData = [];

                      for (var m = 0; m < newData.length; m++ ) {
                        // console.log(newData[m].building.toString());
                        var propBuilding = v.building.toString();
                        var unitBuilding = newData[m].building.toString();
                        // console.log(unitBuilding);
                        // console.log(propBuilding);
                        if ( unitBuilding === propBuilding ) {
                          // console.log('works');
                          unitData.push(newData[m]);
                        }
                      }
                      // console.log(unitData);

                      if (unitData.length > 0 ) {
                        output += '<div class="property-search-result flex-grid flex-grid--fluid-gutter">';
                        output += '<div class="col col35 property"><div class="property-sticky"><div class="property-image u-ofit"><a href="' + v.link + '"><img src="' + v.image + '" alt="' + v.alt + '" /></a></div><div class="property-details"><a class="blk" href="' + v.link + '"><h3>' + v.name + '</h3><p>' + v.address + '</p></a><a class="has-arrow blue" href="' + v.link + '">View Building</a></div></div></div>';
                        output += '<div class="col col60 property-units"><div class="unit-toggle">';
                        output += unitData.length;
                        if ( unitData.length === 1 ) {
                          output += ' Unit Available';
                        } else {
                          output += ' Units Available';
                        }
                        output += '</div><div class="units-content">';
                      }

                      $.each(unitData, function(key, val) {
                        output += '<div class="unit col">';
                        output += '<h2>' + val.unit_number + '</h2>';
                        output += '<div class="flex-grid flex-grid--fluid-gutter"><div class="col30"><h4>Features</h4><ul class="u-no-bullets">';
                        for (var i in val.features) {
                          output += '<li>' + val.features[i] + '</li>';
                        }
                        output += '</ul>';
                        if (val.floorplan) {
                        output += '<a class="download-link u-show-for-mobile" href="' + val.floorplan + '" target="_blank">Floorplan</a>';
                        }
                        output += '</div>';
                        output += '<div class="col30"><h4>Size</h4><ul class="u-no-bullets">';
                        // for (var i in val.size) {
                        output += '<li>' + val._embedded['wp:term'][1][0].name + ' </li>';
                        output += '<li>' + val.size_sqft + ' SQFT</li>';
                        // }
                        output += '</ul></div>';
                        output += '<div class="col30"><h4>Status</h4><ul class="u-no-bullets">';
                        if(val.available.length >= 1) {
                          output += '<li>Available Immediately</li>';
                        } else {
                          output += '<li>Available ' + val.avail_date + '</li>';
                        }
                        output += '<li>$' + val.price_range + ' per month</li>';
                        if(val.custom_status !== null ) {
                          output += '<li>' + val.custom_status + '</li>';
                        }
                        output += '</ul></div><div class="col30 u-hide-for-mobile">';
                        if (val.floorplan) {
                        output += '<a class="download-link" href="' + val.floorplan + '" target="_blank">Floorplan</a>';
                        }
                        output += '</div><div class="col65"><button class="request-viewing" data-content=".content-' + val.unique_id + '">Request Viewing</button></div></div>';
                        output += '</div>';
                        output += '<div class="modal-content modal-content__viewing content-' + val.unique_id + '" data-unit="' + val.unit_number + '" data-building="' + val._embedded['wp:term'][0][0].name + '">';
                        output += '<div class="flex-grid flex-grid--fluid-gutter"><div class="col col40"><h2 class="allcaps-heading">Request a Viewing</h2><h3>' + val.unit_number + ' &ndash; ' + val._embedded['wp:term'][0][0].name + '</h3>';
                        output += '<ul><li>' + val._embedded['wp:term'][1][0].name + ' </li>';
                        output += '<li>Available ' + val.avail_date + ' </li>';
                        output += '<li>$' + val.price_range + ' per month</li></ul>';
                        output += '</div><div class="col col55"><div class="contact-form">' + val.form_shortcode + '</div></div></div></div>';
                    });
                    output += '</div></div>';
                    output += '</div>';
                    });

                    output += '';
                    $('.unit-results').html(output);

                  } else {
                    // console.log(data);
                    var output = '<div class="property-search-result no-result flex-grid flex-grid--fluid-gutter"><h3>Sorry, no results were found. Please search again.</h3></div>';

                    $('.unit-results').html(output);
                  }

                }); //get JSON
             // console.log(prop);
            });//get JSON properties

        }

        $('.trigger-search-form').click(function() {
          // console.log('test');
          searchForm();
        });

        // $('.trigger-search-form').click(function() {
        //   console.log('test');
        //   var selectCity = $('.city').find('.same-as-selected').attr('data-id');
        //     var selectUnit = $('.unit-type').find('.same-as-selected').attr('data-id');
        //     var sliderMin = $('.slider-min').html().substring(1);
        //     var sliderMax = $('.slider-max').html().substring(1);

        //     console.log(selectCity);
        //     console.log(selectUnit);
        //     console.log(sliderMin);
        //     console.log(sliderMax);
        //   // searchForm();
        // });




        /*============================================================================
          SET WIDTH OF HEADER IMAGE AND OFFSET LEFT MARGIN
        ==============================================================================*/

        // function setImgWidth() {
        //   var $containerInner = $('.banner .container--inner'),
        //       $containerWidth = $containerInner.width(),
        //       $screenWidth = $(window).width(),
        //       $leftMargin = ($screenWidth - $containerWidth) / 2,
        //       $imgWidth = $screenWidth - $leftMargin;

        //  $('.content__full-img').css({'width':$imgWidth, 'margin-left':$leftMargin});

        // }
        // setImgWidth();

        // window.addEventListener("resize", setImgWidth);


        /*============================================================================
          MOBILE SEARCH FORM TOGGLE
        ==============================================================================*/

        function mobileSearch() {

          var openSearch = $('.open-search'),
              mobileSearch = $('.mobile-search'),
              closeSearch = $('.close-search');

          openSearch.on('click', function() {
            clicked_el = this;
            // console.log('clicked');
            $('body').addClass('has-search-overlay');
            $(closeSearch).focus();
          });

            $(closeSearch).on('click', function() {
              $('body').removeClass('has-search-overlay');
              $(clicked_el).focus();
            });

        }
        mobileSearch();

        /*============================================================================
          CONTACT FORM 7
        ==============================================================================*/

        document.addEventListener( 'wpcf7mailsent', function( event ) {
            // alert('Submit');

            // location = '#';
            // console.log('test');

            if ( 665 === event.detail.contactFormId ) {
              // console.log('665');
                // event.preventDefault();
                var wtlstContent = $('.modal-content__waitlist-ty').html(),
                wtlstOverlay = $('.overlay-content');
                // console.log(wtlstContent);
                // console.log(wtlstOverlay);
                $(wtlstOverlay).html(wtlstContent);
                // toggleNav();
                // do something productive
            } else if ( 664 === event.detail.contactFormId ) {
                // event.preventDefault();
                // console.log('664');
                var viewContent = $('.modal-content__viewing-ty').html(),
                    viewOverlay = $('.overlay-content');

                $(viewOverlay).html(viewContent);
                // toggleNav();
            } else {
              // console.log('other form');
            }

        }, false );

        function customSpinner() {
          if( $('.contact-form') && $('.wpcf7-spinner') ) {
            $('.wpcf7-spinner').remove();
            $('.contact-form .wpcf7-submit').after('<span class="wpcf7-spinner ajax-loader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></span>');
          }
        }
        customSpinner();


        /*============================================================================
          PAUSE VIDEO ON HOME PAGE
        ==============================================================================*/

        //not enabled ** need to make conditional for pages with video only

        function pauseVideo() {
          var menuToggle = ('.menu-toggle');
          var banner = ('.banner');
          var homeVideo = document.getElementById("home-bg-video");
          var menuOverlay = ('.menu-overlay');
          var homeBg = ('.home-bg');

          $(menuToggle).click(function() {
            if ( $(banner).hasClass('nav-toggled') ) {
              homeVideo.pause();
            } else {
              homeVideo.play();
            }
          });

          $(menuOverlay).click(function() {
            if ( $(banner).hasClass('nav-toggled') ) {
              homeVideo.pause();
            } else {
              homeVideo.play();
            }
          });

          $(window).scroll(function() {
            var wrapElement = $('.wrap');
            var elementTop = $(wrapElement).offset().top;
            var scrollTop = $(window).scrollTop();
            // console.log(scrollTop);
            if( scrollTop > elementTop || $('.banner').hasClass('nav-toggled') ) {
              // homeVideo.pause();
              // $(homeVideo).css({ 'position':'relative' });
              $(homeBg).css({ 'position':'relative' });
              $('.banner').addClass('home-sticky');
              // console.log('pause');
            } else {
              // homeVideo.play();
              // $(homeVideo).css({ 'position':'fixed' });
              $(homeBg).css({ 'position':'fixed' });
              $('.banner').removeClass('home-sticky');
              // console.log('play');
            }
          });

        }

        //pauseVideo();

        /*============================================================================
          HOME PAGE NAV
        ==============================================================================*/

        function homeNav() {
          var menuToggle = ('.menu-toggle');
          var banner = ('.banner');
          var homeVideo = document.getElementById("home-bg-video");
          var menuOverlay = ('.menu-overlay');
          var homeBg = ('.home-bg');

          $(window).scroll(function() {
            var wrapElement = $('.wrap');
            var elementTop = $(wrapElement).offset().top;
            var scrollTop = $(window).scrollTop();
            // console.log(scrollTop);
            if( scrollTop > elementTop || $('.banner').hasClass('nav-toggled') ) {
              // homeVideo.pause();
              // $(homeVideo).css({ 'position':'relative' });
              $(homeBg).css({ 'position':'relative' });
              $('.banner').addClass('home-sticky');
            } else {
              // homeVideo.play();
              // $(homeVideo).css({ 'position':'fixed' });
              $(homeBg).css({ 'position':'fixed' });
              $('.banner').removeClass('home-sticky');
            }
          });
        }
        homeNav();

        /*============================================================================
          LOCATION TOGGLE HOME PAGE
        ==============================================================================*/

        function locationToggle() {
          $('.button-sm').click(function() {
            $(this).toggleClass('open');
            $('.property-filter').slideToggle();
          });
        }
        locationToggle();


        /*============================================================================
          PROPERTY FILTER
        ==============================================================================*/

        function filterProps() {
          var $radios = $('input[name="location"]');

          $radios.change(function() {
            var $checked = $radios.filter(':checked');
            var $location = $checked.val();
            // console.log($location);

            var userDeviceWidth = $(window).width();
            if ( userDeviceWidth < 1024 ) {
            // $('.button-sm').removeClass('open');
              $('.property-filter').hide();
              $('.button-sm').removeClass('open');

            }

            $('html, body').animate({
              scrollTop: $('.properties').offset().top - 100
            }, 700);


            $('.properties .property').each(function() {

              if( $location === 'all') {
                $(this).show();
                $('.anim').addClass('always-go');
                $('.slide-up').addClass('always-go');
              }
              else if( $(this).hasClass($location) ) {
                $(this).show();
                $('.anim').addClass('always-go');
                $('.slide-up').addClass('always-go');
              } else {
                $(this).hide();
                $('.anim').addClass('always-go');
                $('.slide-up').addClass('always-go');
              }
            });

          });

        }
        filterProps();

        /*============================================================================
          STICKY NAV
        ==============================================================================*/

        $(function() {
          $(window).scroll(function() {
            var header = ('.banner');
            var scrollTop = $(window).scrollTop();

            if ( scrollTop > 0 ){
              $(header).addClass('sticky');
            } else {
              $(header).removeClass('sticky');
            }
          });
        });

        /*============================================================================
          STICKY ADDRESS
        ==============================================================================*/

        $(function() {
          var userDeviceWidth = $(window).width();
          var addressTop;
            if ( userDeviceWidth <= 767 ) {
              addressTop = 325;
            } else {
              addressTop = 425;
            }
          $(window).scroll(function() {
            var scrollTop = $(window).scrollTop();

            if ( scrollTop > addressTop ){
              $('body').addClass('has-sticky');
              // console.log(scrollTop);
            } else {
              $('body').removeClass('has-sticky');
            }
          });
        });

        /*============================================================================
          MENU TOGGLE
        ==============================================================================*/

        function toggleNav() {
          var menuToggle = ('.menu-toggle-wrapper');
          var navSecondary = ('.nav-secondary');
          var banner = ('.banner');
          var menuOverlay = ('.menu-overlay');
          var homeVideo = document.getElementById("home-bg-video");
          // var placeHolder = searchField.attr('placeholder', 'Type to search properties');

          $(menuToggle).click(function() {
            // console.log('clicked');
            if ( $(banner).hasClass('nav-toggled') ) {
              // console.log('remove toggle');
              // homeVideo.play();
              $(banner).removeClass('nav-toggled');
              $('body').removeClass('u-no-scroll has-overlay');
            } else {
              // homeVideo.pause();
              // console.log('toggle');
              $(banner).addClass('nav-toggled');
              $('body').addClass('u-no-scroll has-overlay');
              $(this).focus();
            }
          });

          $(menuOverlay).click(function() {
            if ( $(banner).hasClass('nav-toggled') ) {
              $(banner).removeClass('nav-toggled');
              $('body').removeClass('u-no-scroll has-overlay');
              // homeVideo.play();
            }
          });

          $('.nav li a').click(function () {
            console.log('clicked');
            if ($(banner).hasClass('nav-toggled')) {
              $(banner).removeClass('nav-toggled');
              $('body').removeClass('u-no-scroll has-overlay');
              // homeVideo.play();
            }
          });
        }
        toggleNav();


        /*============================================================================
          SCROLL TO ANIMATION
        ==============================================================================*/

        /* https://css-tricks.com/snippets/jquery/smooth-scrolling/ */
        function scrollToAnchor() {
          $('a[href*="#"]:not([href="#"])').on('click', function() {
            if ( location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') &&
             location.hostname === this.hostname &&
             !$(this).hasClass("no-move") ) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top + 1
                }, 700);
              }
                return false;
            }
          });
        }
        scrollToAnchor();


        /*============================================================================
          FEATURE PROPERTY MODAL + COOKIES
        ==============================================================================*/

        function setCookie(cname, cvalue, exdays) {
          var d = new Date();
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          var expires = "expires="+ d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
          var name = cname + "=";
          var ca = document.cookie.split(';');
          for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        }

        function featureProp() {
          var body = $('body'),
              featureModal = $('.feature-overlay'),
              closeFeature = $('.close-feature, .feature-wrapper, .popup-btn'),
              feature = getCookie('feature');

          if( featureModal.length > 0 && feature === '' ) {

            setTimeout(function(){
              body.addClass('show-feature');
            }, 5000);

          }

          closeFeature.on('click', function() {
            body.removeClass('show-feature');
            setCookie('feature', 'feature', 7);
          });
        }
        featureProp();

        /*============================================================================
          MODAL - NEWSLETTER
        ==============================================================================*/

            //newsletter
            function toggleNewsModal() {
            var toggleNews = $('.toggle-news'),
                newsContent = $('.modal-content__newsletter'),
                newsModal = $('.overlay-content'),
                closeBio = $('.close-overlay'),
                closeEverywhere = $('.overlay-wrapper');

            toggleNews.on('click', function(e) {
              e.preventDefault();
              clicked_el = this;

              var newsModalContent = newsContent.html();

              $('body').addClass('has-info-overlay');
              $(newsModal).html(newsModalContent);
              $(closeBio).focus();
            });

              $(closeBio).on('click', function() {
                $('body').removeClass('has-info-overlay');
                $(clicked_el).focus();
              });

              $(closeEverywhere).on('click', function() {
                $('body').removeClass('has-info-overlay');
                $(clicked_el).focus();
              });

            }
            toggleNewsModal();


        /*============================================================================
          MODAL - VIEWING REQUEST
        ==============================================================================*/

            function requestViewing() {

              var toggleRequest = $('.request-viewing'),
                  requestContent = $('.modal-content__viewing'),
                  requestModal = $('.overlay-content'),
                  closeModal = '.close-overlay',
                  closeEverywhere = $('.overlay-wrapper');

              toggleRequest.on('click', function(e) {
                e.preventDefault();
                clicked_el = this;
                var modalContent = $(this).attr('data-content');
                // console.log(modalContent);

                var unitNum = $(modalContent).attr('data-unit');
                var building = $(modalContent).attr('data-building');
                var requestModalContent = $(modalContent).html();


              // console.log(unitNum);
              // console.log(building);

                $('body').addClass('has-info-overlay');
                $(requestModal).html(requestModalContent);
                $("input[type='hidden'][name='unit-number']").val(unitNum);
                $("input[type='hidden'][name='building-name']").val(building);
                // $(closeModal).focus();

                var wpcf7_form = document.getElementsByClassName('wpcf7-form');
                [].forEach.call(wpcf7_form, function( form ) {
                  wpcf7.init( form );
                  $('.wpcf7-spinner').remove();
                });

                 $('.overlay-content .wpcf7-submit').after('<span class="wpcf7-spinner ajax-loader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></span>');

              });

              $(document).on('click', closeModal, function() {
                $('body').removeClass('has-info-overlay');
                // $(clicked_el).focus();
              });

              $(closeEverywhere).on('click', function() {
                $('body').removeClass('has-info-overlay');
                // $(clicked_el).focus();
              });
            }
            requestViewing();

        /*============================================================================
          MODAL - WAITLIST
        ==============================================================================*/

            function joinWaitlist() {
              var toggleRequest = $('.join-waitlist');
              var requestContent = $('.modal-content__waitlist');
              var requestModal = $('.overlay-content');
              var closeModal = '.close-overlay';
              var closeEverywhere = $('.overlay-wrapper');


              toggleRequest.on('click', function(e) {
                e.preventDefault();
                clicked_el = this;

                var modalContent = $(this).attr('data-content'),
                    building = $(modalContent).attr('data-building'),
                    requestModalContent = $(modalContent).html();

                $('body').addClass('has-info-overlay');
                $(requestModal).html(requestModalContent);
                // $("input[type='hidden'][name='simplehidden-73']").val(unitNum);
                $("input[type='hidden'][name='building-name']").val(building);
                // $(closeModal).focus();

                var wpcf7_form = document.getElementsByClassName('wpcf7-form');
                [].forEach.call(wpcf7_form, function( form ) {
                  wpcf7.init( form );
                  $('.wpcf7-spinner').remove();
                });

                $('.overlay-content .wpcf7-submit').after('<span class="wpcf7-spinner ajax-loader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></span>');

              });

              $(document).on('click', closeModal, function() {
                $('body').removeClass('has-info-overlay');
                // $(clicked_el).focus();
              });

              $(closeEverywhere).on('click', function() {
                $('body').removeClass('has-info-overlay');
                // $(clicked_el).focus();
              });
            }
            joinWaitlist();

       /*============================================================================
          ACCORDION TOGGLE
        ==============================================================================*/

          function toggleAccordion() {
            var accordionToggle = $('.u-accordion-toggle'),
                accordionPanel = $('.u-accordion-panel');

            $(accordionToggle).each(function() {
              var panel = $(this).next(accordionPanel);
              // console.log(panel);

              $(this).click( function() {
                if ( panel.is(':visible') ) {
                  $(this).removeClass('toggled');
                  $(panel).slideUp();
                } else {
                  $(this).addClass('toggled');
                  $(panel).slideDown();
                }
              });
            });
          }
          toggleAccordion();

        /*============================================================================
          ACCORDION TOGGLE - UNITS MOBILE
        ==============================================================================*/

          function toggleUnits() {
            var accordionToggle = $('.unit-toggle'),
                accordionPanel = $('.units-content');

            $(accordionToggle).each(function() {
              var panel = $(this).next(accordionPanel);
              // console.log(panel);

              $(this).click( function() {
                if ( panel.is(':visible') ) {
                  $(this).removeClass('toggled');
                  $(panel).slideUp();
                } else {
                  $(this).addClass('toggled');
                  $(panel).slideDown();
                }
              });
            });
          }
          toggleUnits();

        /*============================================================================
          #TAB SWITCHER - GALLERY
        ==============================================================================*/

          function tabSwitcher() {

            var $tab_switcher = $('.js-tab-switch');
            var $tab_content = $('.sub-gallery');

            $tab_switcher.on('click', function() {
              var $self = $(this);
              var link = $self.attr('data-content');
              // console.log(link);
              var $target = $(link);
              // console.log($target);

              if (!$self.hasClass('is-active')) {
                $tab_switcher.removeClass('is-active');
                $self.addClass('is-active');
                // $target.addClass('is-active');
              }

              if (!$target.hasClass('active-tab')) {
                $tab_content.removeClass('active-tab');
                $target.addClass('active-tab');
              }

            });

          } tabSwitcher();


        /*============================================================================
          # ANIMATIONS + WAYPOINTS
        ==============================================================================*/

        var slideUp = $('.slide-up').waypoint({
          handler: function(direction) {
            if (direction === 'down'){
              $(this.element).addClass('go');
            } else {
              $(this.element).removeClass('go');
            }
          },
          offset: '100%'
        });

        var slideParallax = $('.slide-up-front').waypoint({
          handler: function(direction) {
            if (direction === 'down'){
              $(this.element).addClass('go');
            } else {
              $(this.element).removeClass('go');
            }
          },
          offset: '70%'
        });

        var slideIn = $('.slide-in').waypoint({
          handler: function(direction) {
            if (direction === 'down'){
              $(this.element).addClass('go');
            } else {
              $(this.element).removeClass('go');
            }
          },
          offset: '86%'
        });

        var animate = $('.anim').waypoint({
          handler: function(direction) {
            if (direction === 'down'){
              $(this.element).addClass('go');
            } else {
              $(this.element).removeClass('go');
            }
          },
          offset: '86%'
        });

          function elParallax() {
            $(window).scroll(function() {
              var userDeviceWidth = $(window).width();
              if ( userDeviceWidth >= 1024 ) {
                $('.parallax-element').each(function() {
                  var speed = 0.15;
                  var elPosition = $(this).offset().top;
                  // console.log(elPosition);
                  var windowYOffset = (window.pageYOffset);
                    // console.log('window' + windowYOffset);
                  var elOffset = (elPosition - windowYOffset);
                    // console.log(elOffset);
                  var elMargin = (elOffset * speed) + "px";
                  // console.log(elMargin);
                  $(this).css({'transform':'translateY(' + elMargin});
                });
                }
              });

          }
          elParallax();


          function parallax() {
            var speed = 0.1;
            $(window).scroll(function() {
              var windowYOffset = (window.pageYOffset);
              var bgPosition = "-" + (windowYOffset * speed) + "px";
            $('.parallax-feature').each(function() {
                $(this).css({top:bgPosition});
                // console.log(bgPosition);
              });
            });
          }
          parallax();


        /*============================================================================
          AJAX ON COMPLETE
        ==============================================================================*/

        $(document).ajaxComplete(function() {
          // console.log('test');
          // toggleNav();
          requestViewing();
          toggleUnits();
          mobileSearch();

          // searchForm();
          // customSelect();
        });

        /*============================================================================
          # Property SINGLE Map
        ==============================================================================*/

          (function($) {

                        /*
            *  add_marker
            *
            *  This function will add a marker to the selected Google Map
            *
            *  @type  function
            *  @date  8/11/2013
            *  @since 4.3.0
            *
            *  @param $marker (jQuery element)
            *  @param map (Google Map object)
            *  @return  n/a
            */

            function add_marker( $marker, map ) {

              // var
              var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

              // create marker
              var marker = new google.maps.Marker({
                position  : latlng,
                map     : map,
                icon: '/app/themes/peterson/assets/images/map-marker.png',
                url: $('#markerUrl').val(),
                scale: 1,
                clickable: true,
              });

              google.maps.event.addListener(marker, 'click', function() {
                // window.location.href = marker.url;
                window.open(marker.url, '_blank');
              });

              // add to array
             map.markers.push( marker );

              // if marker contains HTML, add it to an infoWindow
              if( $marker.html() )
              {
                // create info window
                var infowindow = new google.maps.InfoWindow({
                  content   : $marker.html()
                });

                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function() {

                  infowindow.open( map, marker );

                });
              }

            }

            /*
            *  center_map
            *
            *  This function will center the map, showing all markers attached to this map
            *
            *  @type  function
            *  @date  8/11/2013
            *  @since 4.3.0
            *
            *  @param map (Google Map object)
            *  @return  n/a
            */

            function center_map( map ) {

              // vars
              var bounds = new google.maps.LatLngBounds();

              // loop through all markers and create bounds
              $.each( map.markers, function( i, marker ){

                var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

                bounds.extend( latlng );

              });

              // only 1 marker?
              if( map.markers.length === 1 )
              {
                // set center of map
                  map.setCenter( bounds.getCenter() );
                  map.setZoom( 14 );
              }
              else
              {
                // fit to bounds
                map.fitBounds( bounds );
              }

            }

            /*
            *  new_map
            *
            *  This function will render a Google Map onto the selected jQuery element
            *
            *  @type  function
            *  @date  8/11/2013
            *  @since 4.3.0
            *
            *  @param $el (jQuery element)
            *  @return  n/a
            */

            function new_map( $el ) {

              // var
              var $markers = $el.find('.marker');


              // vars
              var args = {
                zoom    : 12,
                minZoom : 9,
                maxZoom : 18,
                scaleControl: false,
                streetViewControl: false,
                overviewMapControl: false,
                gestureHandling: 'cooperative',
                disableDoubleClickZoom: false,
                center    : new google.maps.LatLng(0, 0),
                mapTypeId : google.maps.MapTypeId.ROADMAP,
                mapTypeControlOptions: {
                mapTypeIds: ['roadmap',
                        'map_style'],
                position: google.maps.ControlPosition.TOP_RIGHT
                },
                panControl: true,
                panControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT
                },
                zoomControl: true,
                zoomControlOptions: {
                  style: google.maps.ZoomControlStyle.LARGE,
                  position: google.maps.ControlPosition.TOP_RIGHT
                },
                scrollwheel: false,
                mapTypeControl: false,
                styles : [
                            {
                                "featureType": "water",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#e9e9e9"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "featureType": "landscape",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 17
                                    }
                                ]
                            },
                            {
                                "featureType": "road.highway",
                                "elementType": "geometry.stroke",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 29
                                    },
                                    {
                                        "weight": 0.2
                                    }
                                ]
                            },
                            {
                                "featureType": "road.arterial",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 18
                                    }
                                ]
                            },
                            {
                                "featureType": "road.local",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "featureType": "poi",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f5f5f5"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "featureType": "poi.park",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#dedede"
                                    },
                                    {
                                        "lightness": 21
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.stroke",
                                "stylers": [
                                    {
                                        "visibility": "on"
                                    },
                                    {
                                        "color": "#ffffff"
                                    },
                                    {
                                        "lightness": 16
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.text.fill",
                                "stylers": [
                                    {
                                        "saturation": 36
                                    },
                                    {
                                        "color": "#333333"
                                    },
                                    {
                                        "lightness": 40
                                    }
                                ]
                            },
                            {
                                "elementType": "labels.icon",
                                "stylers": [
                                    {
                                        "visibility": "off"
                                    }
                                ]
                            },
                            {
                                "featureType": "transit",
                                "elementType": "geometry",
                                "stylers": [
                                    {
                                        "color": "#f2f2f2"
                                    },
                                    {
                                        "lightness": 19
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative",
                                "elementType": "geometry.fill",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 20
                                    }
                                ]
                            },
                            {
                                "featureType": "administrative",
                                "elementType": "geometry.stroke",
                                "stylers": [
                                    {
                                        "color": "#fefefe"
                                    },
                                    {
                                        "lightness": 17
                                    },
                                    {
                                        "weight": 1.2
                                    }
                                ]
                            }
                        ]
              };


              // create map
              var map = new google.maps.Map( $el[0], args);


              // add a markers reference
              map.markers = [];


              // add markers
              $markers.each(function(){

                  add_marker( $(this), map );

              });


              // center map
              center_map( map );


              // return
              return map;

            }

            /*
            *  document ready
            *
            *  This function will render each map when the document is ready (page has loaded)
            *
            *  @type  function
            *  @date  8/11/2013
            *  @since 5.0.0
            *
            *  @param n/a
            *  @return  n/a
            */
            // global var
            var map = null;

            $(document).ready(function(){

              $('.acf-map').each(function(){

                // create map
                map = new_map( $(this) );

              });

            });

            })(jQuery);






      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /*============================================================================
          MODERNIZR - FALLBACKS
        ==============================================================================*/

        // object-fit detection ie

        if ( ! Modernizr.objectfit ) {
          $('.u-ofit').each(function () {
            var $container = $(this),
                imgUrl = $container.find('img').prop('src');
            if (imgUrl) {
              $container
                .css('backgroundImage', 'url(' + imgUrl + ')')
                .addClass('compat-object-fit');
            }
          });
        }


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

          // var homeVideo = document.getElementById("home-bg-video");
          // $(window).scroll(function() {
          //   homeVideo.pause();
          // });

        /*============================================================================
          Scroll Magic
        ==============================================================================*/

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page


          /*============================================================================
            # COUNTER - Animate counter on scroll or in view
          ==============================================================================*/

            function countUp() {
              var options = {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
              };

                var $amount = $('#number-1').data('count');
                // console.log($amount);
                var numAnim = new CountUp('number-1', 0, $amount, 0, 2.5, options);
                // console.log(numAnim);
                if (!numAnim.error) {
                  $('#number-1').addClass('counting');
                  numAnim.start();
                } else {
                  console.error(numAnim.error);
                }
            }

            function countUpTwo() {
              var options = {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
              };
                var $amount = $('#number-2').data('count');
                // console.log($amount);
                var numAnim = new CountUp('number-2', 0, $amount, 0, 2.5, options);
                // console.log(numAnim);
                if (!numAnim.error) {
                  $('#number-2').addClass('counting');
                  numAnim.start();
                } else {
                  console.error(numAnim.error);
                }
            }

            function countUpThree() {
              var options = {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
              };
                var $amount = $('#number-3').data('count');
                // console.log($amount);
                var numAnim = new CountUp('number-3', 0, $amount, 0, 2.5, options);
                if (!numAnim.error) {
                  $('#number-3').addClass('counting');
                  numAnim.start();
                } else {
                  console.error(numAnim.error);
                }
            }

            function countUpFour() {
              var options = {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
              };
                var $amount = $('#number-4').data('count');
                // console.log($amount);
                var numAnim = new CountUp('number-4', 0, $amount, 0, 2.5, options);
                if (!numAnim.error) {
                  $('#number-4').addClass('counting');
                  numAnim.start();
                } else {
                  console.error(numAnim.error);
                }
            }

            function countUpFive() {
              var options = {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
              };
                var $amount = $('#number-5').data('count');
                // console.log($amount);
                var numAnim = new CountUp('number-5', 0, $amount, 0, 2.5, options);
                if (!numAnim.error) {
                  $('#number-5').addClass('counting');
                  numAnim.start();
                } else {
                  console.error(numAnim.error);
                }
            }

            function countUpSix() {
              var options = {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
              };
                var $amount = $('#number-6').data('count');
                // console.log($amount);
                var numAnim = new CountUp('number-6', 0, $amount, 0, 2.5, options);
                if (!numAnim.error) {
                  $('#number-6').addClass('counting');
                  numAnim.start();
                } else {
                  console.error(numAnim.error);
                }
            }

            var counter = $('#number-1').waypoint({
              handler: function(direction) {
                if (direction === 'down'){
                  countUp();
                  countUpTwo();
                  countUpThree();
                  countUpFour();
                  countUpFive();
                  countUpSix();
                }
              },
              offset: '100%'
            });

            //end about us scripts

      }
    },

    // About us page, note the change from about-us to about_us.
    'contact_us': {
      init: function() {
        // JavaScript to be fired on the about us page

        /*============================================================================
        CONTACT FORM 7 CUSTOM investment optgroup
        ==============================================================================*/

        var foundin = $('.menu-614 select option:contains("optgroup-")');
        $.each(foundin, function(value){
          var updated = $(this).val().replace('optgroup-','');
          $(this).nextUntil('option:contains("endoptgroup")').wrapAll('<optgroup label="'+updated+'"></optgroup>');
        });
        $('.menu-614 select option:contains("optgroup-")').remove();
        $('.menu-614 select option:contains("endoptgroup")').remove();

      }
    },

    // About us page, note the change from about-us to about_us.
    'single_properties': {
      init: function() {
        // JavaScript to be fired on the about us page

        /*============================================================================
          SLICK GALLERY - GALLERY
        ==============================================================================*/

        $(document).ready(function(){
          $('.slick-gallery').slick({
            arrows: true,
            infinite: true,
            dots: true,
            draggable: false,
          });
        });


        /*============================================================================
          LOAD MORE UNITS
        ==============================================================================*/

        function loadMoreUnits() {

          var unit = $('.hide-unit');
          $('.btn-load-more').click( function() {
            if($(this).hasClass('loaded') ) {
              $(this).removeClass('loaded');
              this.innerHTML = 'Show More Units';
              $(unit).removeClass('show');
            } else {
              $(this).addClass('loaded');
              this.innerHTML = 'Show Less';
              $(unit).addClass('show');
            }

          });
        }
        loadMoreUnits();

        /*============================================================================
          SLICK GALLERY - UNITS
        ==============================================================================*/

        function unitsSlider() {
          var userDeviceWidth = $(window).width();
          if ( userDeviceWidth < 1024 ) {
            if ( $('.slick-units') ) {
              $('.slick-units').slick({
                arrows: true,
                infinite: true,
                dots: true,
                speed: 300,
                slidesToShow: 1,
                adaptiveHeight: true
              });


              // $('.slick-prev').clone().prependTo('.slick-units').addClass('prev-top');
              // $('.slick-next').clone().appendTo('.slick-units').addClass('next-top');
              // $('.slick-dots').clone().appendTo('.slick-units').addClass('dots-top');
            }

          }
        }
        //unitsSlider();

        /*============================================================================
          ???
        ==============================================================================*/

        function getSum(total, num) {
          return total + num;
        }

        /*============================================================================
          GALLERY TABS
        ==============================================================================*/

        //gallery tab width mobile
        function galleryTabWidth() {
          var userDeviceWidth = $(window).width();
          if ( userDeviceWidth < 1024 ) {
            var allTabsArray = [];
            var galleryTab = $('.gallery-tab');

            $(galleryTab).each(function() {
              var tabWidth = $(this).width() + 40;
              allTabsArray.push(tabWidth);
            });
            if ( $('.gallery-tabs') ) {
              var galleryWidth = allTabsArray.reduce(getSum);

              if( galleryWidth > userDeviceWidth ) {
                $('.gallery-tabs').width(galleryWidth);
              }

            }

          }
        }
        galleryTabWidth();

      }
    },


    //commercial

    'find_an_apartment': {
      init: function() {

        // function homeNav() {
        //   var menuToggle = ('.menu-toggle');
        //   var banner = ('.banner');
        //   // var homeVideo = document.getElementById("home-bg-video");
        //   var menuOverlay = ('.menu-overlay');
        //   var homeBg = ('.home-bg');

        //   $(window).scroll(function() {
        //     var wrapElement = $('.wrap');
        //     var elementTop = $(wrapElement).offset().top;
        //     var scrollTop = $(window).scrollTop();
        //     // console.log(scrollTop);
        //     if( scrollTop > elementTop || $('.banner').hasClass('nav-toggled') ) {
        //       // homeVideo.pause();
        //       // $(homeVideo).css({ 'position':'relative' });
        //       $(homeBg).css({ 'position':'relative' });
        //       $('.banner').addClass('home-sticky');
        //     } else {
        //       // homeVideo.play();
        //       // $(homeVideo).css({ 'position':'fixed' });
        //       $(homeBg).css({ 'position':'fixed' });
        //       $('.banner').removeClass('home-sticky');
        //     }
        //   });
        // }
        // homeNav();

        // // price sldier

        // function priceSlider() {
        //   var userDeviceWidth = $(window).width();
        //   if ( userDeviceWidth >= 1024 ) {
        //     var slider = document.getElementById('price-slider');
        //   } else {
        //     var slider = document.getElementById('price-slider-mobile');
        //   }

        //   console.log('sliuder');

        //   if(slider) {

        //     console.log('theres a slider');

        //     document.getElementById('slider-min').innerHTML = '700';
        //     document.getElementById('slider-max').innerHTML = '5000';

        //     noUiSlider.create(slider, {
        //         start: [500, 5000],
        //         connect: true,
        //         step: 500,
        //         ariaFormat: wNumb({
        //             decimals: 0
        //         }),
        //         format: wNumb({
        //             decimals: 0,
        //             thousand: '',
        //             prefix: '$'
        //         }),
        //         range: {
        //             'min': 500,
        //             'max': 5000
        //         }
        //     });

        //     var snapValues = [
        //       document.getElementById('slider-min'),
        //       document.getElementById('slider-max')
        //     ];

        //     slider.noUiSlider.on('update', function (values, handle) {
        //         snapValues[handle].innerHTML = values[handle];
        //     });
        //   }
        // }
        //priceSlider();

      } //end init



    } //end find an apartment

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
